// ** Icon imports
import LockOutline from 'mdi-material-ui/LockOutline'
import HomeOutline from 'mdi-material-ui/HomeOutline'
import AccountOutline from 'mdi-material-ui/AccountOutline'
import FolderOutline from 'mdi-material-ui/FolderOutline'

import FileMultipleOutline from 'mdi-material-ui/FileMultipleOutline'
import AccountMultipleOutline from 'mdi-material-ui/AccountMultipleOutline'
import Lifebuoy from 'mdi-material-ui/Lifebuoy'
import FolderMultipleOutline from 'mdi-material-ui/FolderMultipleOutline'

// ** Type import
import { NavGroup, NavLink, NavSectionTitle, VerticalNavItemsType } from 'src/@core/layouts/types'
import { ReactNode } from 'react'
import { SvgIcon } from '@mui/material'

const getProjectIcon = (project: string): string | string[] | ReactNode | typeof SvgIcon => {
  if (project === 'autopilot') {
    return FolderOutline
  } else if (project === 'console') {
    return FolderOutline
  } else if (project === 'xxx') {
    return FolderOutline
  } else {
    return FolderOutline
  }
}

const navigation = (
  isAdmin: boolean,
  projects: { id: string; prettyName?: string | null; description?: string | null }[]
): VerticalNavItemsType => {
  let projectSection: (NavLink | NavGroup | NavSectionTitle)[] = []
  if (projects.length > 0) {
    projectSection = [
      {
        sectionTitle: 'Projects'
      },
      ...projects.map(project => ({
        title: project.prettyName ? project.prettyName : project.id,
        icon: getProjectIcon(project.id),
        path: `/projects/${project.id}`
      }))
    ]
  }

  let adminSection: (NavLink | NavGroup | NavSectionTitle)[] = []
  if (isAdmin) {
    adminSection = [
      {
        sectionTitle: 'Administration'
      },
      {
        title: 'Users',
        icon: AccountMultipleOutline,
        path: '/admin/users/list'
      },
      {
        title: 'Projects',
        icon: FolderMultipleOutline,
        path: '/admin/projects/list'
      }
    ]
  }

  return [
    {
      title: 'Home',
      icon: HomeOutline,
      path: '/home'
    },
    {
      title: 'Files',
      icon: FileMultipleOutline,
      path: '/files/list'
    },
    ...projectSection,
    {
      sectionTitle: 'Profile'
    },
    {
      title: 'Account',
      icon: AccountOutline,
      path: '/profile/account/'
    },

    {
      title: 'Security',
      icon: LockOutline,
      path: '/profile/security'
    },
    ...adminSection,
    {
      sectionTitle: 'Help'
    },
    {
      title: 'Support',
      icon: Lifebuoy,
      path: '/support'
    }
  ]
}

export default navigation
