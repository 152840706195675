// ** MUI Import
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import { Typography } from '@mui/material'
import MagothyLogo from 'src/utils/frontend/MagothyLogo'
import { useEffect, useState } from 'react'

interface SpinnerProps {
  text?: string
}

const FallbackSpinner = ({ text }: SpinnerProps) => {
  // ** Hook
  const theme = useTheme()
  const [mounted, setMounted] = useState<boolean>(false)

  // there's a weird issue where the Magothy Logo flashes in the upper left
  // corner of the screen before going to the correct location
  // Waiting to show the logo until after the component as been "mounted"
  // fixes this issue
  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      {mounted && <MagothyLogo width='150' />}
      <CircularProgress disableShrink sx={{ mt: 6 }} />
      {text && (
        <Typography sx={{ mt: 6 }} color={theme.palette.primary.main} variant={'h6'}>
          {text}
        </Typography>
      )}
    </Box>
  )
}

export default FallbackSpinner
