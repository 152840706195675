import { useTheme } from '@mui/material'

export interface MagothyLogoProps {
  width: string
}

const MagothyLogo = ({ width }: MagothyLogoProps) => {
  const theme = useTheme()

  return (
    <svg
      width={width}
      viewBox='0 0 2792 1292'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g transform='matrix(4.16667,0,0,4.16667,-108.254,-8.33027)'>
        <g id='Layer-1'>
          <g transform='matrix(1,0,0,1,637.126,166.22)'>
            <path
              d='M0,90.67C-41.885,116.499 -99.968,144.691 -150.831,141.372C-180.167,139.459 -302.934,128.617 -302.934,128.617L-283.164,135.633C-283.164,135.633 -290.179,144.243 -299.426,142.649C-308.672,141.054 -312.818,131.487 -321.11,131.807C-329.399,132.125 -334.501,130.531 -334.183,121.603C-333.849,112.25 -307.689,108.636 -293.691,97.209C-401.313,141.873 -519.241,165.665 -606.105,121.472C-520.746,156.802 -381.729,127.738 -310.116,88.28C-310.116,88.28 -323.827,92.587 -333.393,96.732C-333.393,96.732 -335.146,95.297 -334.35,92.427C-333.553,89.557 -324.626,86.209 -323.829,80.947C-323.031,75.686 -323.35,66.917 -315.856,67.555C-308.364,68.191 -287.841,69.464 -276.318,61.974C-242.513,40.001 -167.688,-37.036 -55.289,-53.669C-67.397,-46.27 -76.826,-36.778 -76.826,-36.778C-56.091,-49.749 7.415,-74.995 19.751,-40.455C22.945,-31.51 39.398,-27.676 47.705,-23.683C56.011,-19.69 54.253,-8.029 51.059,-4.355L29.647,14.894L41.105,10.8C37.267,17.226 32.848,25.33 28.864,34.388C21.108,52.024 20.138,78.252 0,90.67'
              fill={theme.palette.primary.main}
            />
          </g>
          <g transform='matrix(1,0,0,1,496.331,167.937)'>
            <path
              d='M0,132.186C24.316,131.873 49.344,124.731 71.521,115.868C85.509,110.277 135.403,87.355 143.432,76.159C146.054,72.503 147.564,68.19 148.434,63.607C143.672,69.638 140.12,60.274 140.12,60.274C140.12,60.274 133.758,45.68 128.008,32.854C119.563,14.023 85.681,0 84.802,18.892C84.482,25.748 80.338,44.083 80.338,44.083C64.481,125.903 0.695,132.122 0,132.186'
              fill={theme.palette.background.default}
            />
          </g>
          <g transform='matrix(1,0,0,1,540.703,107.208)'>
            <path
              d='M0,4.578C-59.832,-39.722 -111.907,-106.391 -173.307,-105.186C-173.307,-105.186 -186.04,-105.962 -188.64,-98.427C-190.219,-93.85 -185.338,-88.9 -180.256,-82.881C-150.835,-48.033 -152.841,58.123 -184.221,110.969C-176.143,109.718 -167.438,101.12 -161.043,95.989C-133.39,73.802 -107.276,51.524 -75.111,35.849C-50.771,22.377 -25.738,11.833 0,4.578'
              fill={theme.palette.primary.dark}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MagothyLogo
