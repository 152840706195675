import crypto from 'crypto'
import { UserResource } from '@clerk/types'

export const getGravatarLink = (email: string): string => {
  const email1 = email.trim().toLocaleLowerCase()
  // remove any +BLAH from email address
  const email2 = email1.replace(/([^@+].*)(\+[^@+].*)(@[^@+].*)/g, '$1$3')
  const emailHash = crypto.createHash('md5').update(email2).digest('hex')
  return `https://www.gravatar.com/avatar/${emailHash}?s=200&r=pg&d=mp`
}

export const getProfilePic = (user: UserResource): string => {
  if (user.profileImageUrl !== 'https://www.gravatar.com/avatar?d=mp') {
    return user.profileImageUrl
  } else if (user.primaryEmailAddress?.emailAddress) {
    return getGravatarLink(user.primaryEmailAddress.emailAddress)
  } else {
    return `/images/avatars/1.png`
  }
}

export const getProfilePic2 = (user: { email: string | undefined; profileImageUrl: string | undefined }): string => {
  if (!!user.profileImageUrl && user.profileImageUrl !== 'https://www.gravatar.com/avatar?d=mp') {
    return user.profileImageUrl
  } else if (user.email) {
    return getGravatarLink(user.email)
  } else {
    return `/images/avatars/1.png`
  }
}
