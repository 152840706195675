// ** MUI Imports
import Box from '@mui/material/Box'
import MuiLink from '@mui/material/Link'
import Link from 'next/link'
import { Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

const FooterContent = () => {
  // ** Var
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography sx={{ mr: 2 }}>
        {`© ${new Date().getFullYear()}, Made by `}
        <MuiLink target='_blank' href='https://magothyrt.com/'>
          Magothy River Technologies
        </MuiLink>
      </Typography>
      {hidden ? null : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', '& :not(:last-child)': { mr: 4 } }}>
          <Link href='/privacy/' passHref>
            <Typography noWrap component='a' sx={{ color: 'primary.main', textDecoration: 'none' }}>
              Privacy Policy
            </Typography>
          </Link>

          <Link href='/terms/' passHref>
            <Typography noWrap component='a' sx={{ color: 'primary.main', textDecoration: 'none' }}>
              Terms &amp; Conditions
            </Typography>
          </Link>

          <Link href='/support/' passHref>
            <Typography noWrap component='a' sx={{ color: 'primary.main', textDecoration: 'none' }}>
              Support
            </Typography>
          </Link>
        </Box>
      )}
    </Box>
  )
}

export default FooterContent
