// ** React Imports
import { ReactNode, useEffect, useState } from 'react'

// ** MUI Imports
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// ** Layout Imports
// !Do not remove this Layout import
import Layout from 'src/@core/layouts/Layout'

// ** Navigation Imports
import VerticalNavItems from 'src/navigation/vertical'
import HorizontalNavItems from 'src/navigation/horizontal'

// ** Component Import
// Uncomment the below line (according to the layout type) when using server-side menu
// import ServerSideVerticalNavItems from './components/vertical/ServerSideNavItems'
// import ServerSideHorizontalNavItems from './components/horizontal/ServerSideNavItems'

import VerticalAppBarContent from './components/vertical/AppBarContent'
import HorizontalAppBarContent from './components/horizontal/AppBarContent'

// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings'
import { ProjectFieldsFragment, useMeQuery, useProjectsLazyQuery } from 'src/generated/graphql'

interface Props {
  children: ReactNode
}

const UserLayout = ({ children }: Props) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [projects, setProjects] = useState<ProjectFieldsFragment[]>([])

  // ** Hooks
  const { settings, saveSettings } = useSettings()
  const meQuery = useMeQuery()

  const [projectsLazyQuery, { called, data }] = useProjectsLazyQuery()

  // set isAdmin
  useEffect(() => {
    if (meQuery.data?.me.isAdmin === true) {
      setIsAdmin(true)
    }
  }, [meQuery.data?.me])

  // set projects for non admin user
  useEffect(() => {
    if (meQuery.data?.me.isAdmin === false) {
      setProjects(meQuery.data.me.projects)
    }
  }, [meQuery.data?.me])

  // get all projects if user is admin
  useEffect(() => {
    if (isAdmin && called === false) {
      projectsLazyQuery()
    }
  }, [called, isAdmin, projectsLazyQuery])

  // set all projects if user is admin
  useEffect(() => {
    if (isAdmin && data?.projects) {
      setProjects(data.projects)
    }
  }, [called, data?.projects, isAdmin, projectsLazyQuery])

  //console.log(`user: ${user !== null && user !== undefined}, isLoaded: ${isLoaded}, isSignedIn: ${isSignedIn}`)
  //console.log(`data: ${JSON.stringify(data, null, 2)}`)

  /**
   *  The below variable will hide the current layout menu at given screen size.
   *  The menu will be accessible from the Hamburger icon only (Vertical Overlay Menu).
   *  You can change the screen size from which you want to hide the current layout menu.
   *  Please refer useMediaQuery() hook: https://mui.com/components/use-media-query/,
   *  to know more about what values can be passed to this hook.
   *  ! Do not change this value unless you know what you are doing. It can break the template.
   */
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  return (
    <Layout
      hideCustomizer={!isAdmin}
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      {...(settings.layout === 'horizontal'
        ? {
            // ** Navigation Items
            horizontalNavItems: HorizontalNavItems(isAdmin, projects),

            // Uncomment the below line when using server-side menu in horizontal layout and comment the above line
            // horizontalNavItems: ServerSideHorizontalNavItems(),

            // ** AppBar Content
            horizontalAppBarContent: props => (
              <HorizontalAppBarContent
                hidden={hidden}
                settings={settings}
                saveSettings={saveSettings}
                setShowBackdrop={props.setShowBackdrop}
              />
            )
          }
        : {
            // ** Navigation Items
            verticalNavItems: VerticalNavItems(isAdmin, projects),

            // Uncomment the below line when using server-side menu in vertical layout and comment the above line
            // verticalNavItems: ServerSideVerticalNavItems(),

            // ** AppBar Content
            verticalAppBarContent: props => (
              <VerticalAppBarContent
                hidden={hidden}
                showThemeToggle={isAdmin}
                settings={settings}
                saveSettings={saveSettings}
                setShowBackdrop={props.setShowBackdrop}
                toggleNavVisibility={props.toggleNavVisibility}
              />
            )
          })}
    >
      {children}
    </Layout>
  )
}

export default UserLayout
