// ** React Imports
import { ReactNode, useEffect } from 'react'

// ** Hooks Import
import Spinner from 'src/@core/components/spinner'
import Error500 from 'src/pages/500'
import { useAuth } from '@clerk/nextjs'
import { useMeLazyQuery } from 'src/generated/graphql'

interface MeGuardProps {
  children: ReactNode
}

/** Ensures that the graphql/me endpoint is called. This will create a user in the database if
 * one does not already exist
 */
const MeGuard = (props: MeGuardProps) => {
  const { children } = props

  const { isLoaded, userId, sessionId, getToken } = useAuth()
  const [meQuery, { data, error, called }] = useMeLazyQuery()

  useEffect(() => {
    if (userId && !called) {
      meQuery()
    }
  }, [called, meQuery, userId])

  if (userId && data?.me) {
    return <>{children}</>
  } else if (error) {
    return <Error500 />
  } else {
    return <Spinner text='Getting user information...' />
  }
}

export default MeGuard
