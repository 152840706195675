// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

// ** Icons Imports
import MenuIcon from 'mdi-material-ui/Menu'
import RefreshIcon from 'mdi-material-ui/Refresh'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

// ** Components
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import Autocomplete from 'src/@core/layouts/components/shared-components/Autocomplete'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import LanguageDropdown from 'src/@core/layouts/components/shared-components/LanguageDropdown'
import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown'
import { Button } from '@mui/material'
import { useApolloClient } from '@apollo/client'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

import { UserButton } from '@clerk/clerk-react'

interface Props {
  hidden: boolean
  settings: Settings
  showThemeToggle: boolean
  toggleNavVisibility: () => void
  setShowBackdrop: (val: boolean) => void
  saveSettings: (values: Settings) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      '& .MuiButton-startIcon': {
        margin: 0
      }
    }
  },
  buttonText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}))

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, saveSettings, setShowBackdrop, toggleNavVisibility } = props
  const classes = useStyles()
  const client = useApolloClient()

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden ? (
          <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
            <MenuIcon />
          </IconButton>
        ) : null}
        <Autocomplete hidden={hidden} setShowBackdrop={setShowBackdrop} />
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
        {props.showThemeToggle && <ModeToggler settings={settings} saveSettings={saveSettings} />}
        {/* <NotificationDropdown settings={settings} /> */}
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  )
}

export default AppBarContent
