import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateProject = {
  __typename?: 'AggregateProject';
  _count?: Maybe<ProjectCountAggregate>;
  _max?: Maybe<ProjectMaxAggregate>;
  _min?: Maybe<ProjectMinAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type ClerkUserModel = {
  __typename?: 'ClerkUserModel';
  birthday: Scalars['String'];
  createdAt: Scalars['Float'];
  emailAddresses: Array<EmailAddressModel>;
  externalAccounts: Array<ExternalAccountModel>;
  externalId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  lastSignInAt?: Maybe<Scalars['Float']>;
  passwordEnabled: Scalars['Boolean'];
  phoneNumbers: Array<PhoneNumberModel>;
  primaryEmailAddressId?: Maybe<Scalars['String']>;
  primaryPhoneNumberId?: Maybe<Scalars['String']>;
  primaryWeb3WalletId?: Maybe<Scalars['String']>;
  profileImageUrl: Scalars['String'];
  twoFactorEnabled: Scalars['Boolean'];
  updatedAt: Scalars['Float'];
  username?: Maybe<Scalars['String']>;
  web3Wallets: Array<Web3WalletModel>;
};

export type CreateInvitationData = {
  emailAddress: Scalars['String'];
  projects: Array<Scalars['String']>;
};

export type DeleteClerkUserInput = {
  userId: Scalars['String'];
};

export type DeleteFileInput = {
  Key: Scalars['String'];
};

export type EmailAddressModel = {
  __typename?: 'EmailAddressModel';
  emailAddress: Scalars['String'];
  id: Scalars['String'];
  linkedTo: Array<IdentificationLinkModel>;
  verification?: Maybe<VerificationModel>;
};

export type ExternalAccountModel = {
  __typename?: 'ExternalAccountModel';
  approvedScopes: Scalars['String'];
  emailAddress: Scalars['String'];
  externalId: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  identificationId: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  picture: Scalars['String'];
  provider: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  verification?: Maybe<VerificationModel>;
};

export type FileData = {
  __typename?: 'FileData';
  Description?: Maybe<Scalars['String']>;
  Key: Scalars['String'];
  Modified: Scalars['DateTime'];
  Name: Scalars['String'];
  Project: Scalars['String'];
  SizeBytes: Scalars['Float'];
};

export type IdentificationLinkModel = {
  __typename?: 'IdentificationLinkModel';
  id: Scalars['String'];
  type: Scalars['String'];
};

export type Invitation = {
  __typename?: 'Invitation';
  createdAt: Scalars['DateTime'];
  emailAddress: Scalars['String'];
  id: Scalars['String'];
  projects: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type Mutation = {
  __typename?: 'Mutation';
  clerkCreateInvitation?: Maybe<Invitation>;
  clerkRevokeInvitation?: Maybe<Invitation>;
  createManyProject: AffectedRowsOutput;
  createManyUser: AffectedRowsOutput;
  createProject: Project;
  createUser: User;
  deleteClerkUser?: Maybe<ClerkUserModel>;
  deleteFile: Scalars['Boolean'];
  deleteManyProject: AffectedRowsOutput;
  deleteManyUser: AffectedRowsOutput;
  deleteProject?: Maybe<Project>;
  deleteUser?: Maybe<User>;
  updateManyProject: AffectedRowsOutput;
  updateManyUser: AffectedRowsOutput;
  updateMe?: Maybe<User>;
  updateProject?: Maybe<Project>;
  updateUser?: Maybe<User>;
  upsertProject: Project;
  upsertUser: User;
};


export type MutationClerkCreateInvitationArgs = {
  data: CreateInvitationData;
};


export type MutationClerkRevokeInvitationArgs = {
  data: RevokeInvitationData;
};


export type MutationCreateManyProjectArgs = {
  data: Array<ProjectCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateProjectArgs = {
  data: ProjectCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationDeleteClerkUserArgs = {
  data: DeleteClerkUserInput;
};


export type MutationDeleteFileArgs = {
  data: DeleteFileInput;
};


export type MutationDeleteManyProjectArgs = {
  where?: InputMaybe<ProjectWhereInput>;
};


export type MutationDeleteManyUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type MutationDeleteProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationUpdateManyProjectArgs = {
  data: ProjectUpdateManyMutationInput;
  where?: InputMaybe<ProjectWhereInput>;
};


export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: InputMaybe<UserWhereInput>;
};


export type MutationUpdateMeArgs = {
  data: UserUpdateInput;
};


export type MutationUpdateProjectArgs = {
  data: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpsertProjectArgs = {
  create: ProjectCreateInput;
  update: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};


export type MutationUpsertUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type PhoneNumberModel = {
  __typename?: 'PhoneNumberModel';
  defaultSecondFactor: Scalars['Boolean'];
  id: Scalars['String'];
  linkedTo: Array<IdentificationLinkModel>;
  phoneNumber: Scalars['String'];
  reservedForSecondFactor: Scalars['Boolean'];
  verification?: Maybe<VerificationModel>;
};

export type Project = {
  __typename?: 'Project';
  _count?: Maybe<ProjectCount>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  prettyName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
};


export type ProjectUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type ProjectCount = {
  __typename?: 'ProjectCount';
  users: Scalars['Int'];
};

export type ProjectCountAggregate = {
  __typename?: 'ProjectCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  prettyName: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ProjectCountOrderByAggregateInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  prettyName?: InputMaybe<SortOrder>;
};

export type ProjectCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  prettyName: Scalars['String'];
};

export type ProjectCreateManyInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  prettyName: Scalars['String'];
};

export type ProjectCreateNestedManyWithoutUsersInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutUsersInput>>;
};

export type ProjectCreateOrConnectWithoutUsersInput = {
  create: ProjectCreateWithoutUsersInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateWithoutUsersInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  prettyName: Scalars['String'];
};

export type ProjectGroupBy = {
  __typename?: 'ProjectGroupBy';
  _count?: Maybe<ProjectCountAggregate>;
  _max?: Maybe<ProjectMaxAggregate>;
  _min?: Maybe<ProjectMinAggregate>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  prettyName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProjectListRelationFilter = {
  every?: InputMaybe<ProjectWhereInput>;
  none?: InputMaybe<ProjectWhereInput>;
  some?: InputMaybe<ProjectWhereInput>;
};

export type ProjectMaxAggregate = {
  __typename?: 'ProjectMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  prettyName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProjectMaxOrderByAggregateInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  prettyName?: InputMaybe<SortOrder>;
};

export type ProjectMinAggregate = {
  __typename?: 'ProjectMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  prettyName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProjectMinOrderByAggregateInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  prettyName?: InputMaybe<SortOrder>;
};

export type ProjectOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProjectOrderByWithAggregationInput = {
  _count?: InputMaybe<ProjectCountOrderByAggregateInput>;
  _max?: InputMaybe<ProjectMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProjectMinOrderByAggregateInput>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  prettyName?: InputMaybe<SortOrder>;
};

export type ProjectOrderByWithRelationInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  prettyName?: InputMaybe<SortOrder>;
};

export enum ProjectScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  PrettyName = 'prettyName',
  UpdatedAt = 'updatedAt'
}

export type ProjectScalarWhereInput = {
  AND?: InputMaybe<Array<ProjectScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProjectScalarWhereInput>>;
  OR?: InputMaybe<Array<ProjectScalarWhereInput>>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  prettyName?: InputMaybe<StringFilter>;
};

export type ProjectScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProjectScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProjectScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProjectScalarWhereWithAggregatesInput>>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  prettyName?: InputMaybe<StringWithAggregatesFilter>;
};

export type ProjectUpdateInput = {
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prettyName?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProjectUpdateManyMutationInput = {
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prettyName?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProjectUpdateManyWithWhereWithoutUsersInput = {
  data: ProjectUpdateManyMutationInput;
  where: ProjectScalarWhereInput;
};

export type ProjectUpdateManyWithoutUsersInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutUsersInput>>;
  delete?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProjectScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  update?: InputMaybe<Array<ProjectUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: InputMaybe<Array<ProjectUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: InputMaybe<Array<ProjectUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type ProjectUpdateWithWhereUniqueWithoutUsersInput = {
  data: ProjectUpdateWithoutUsersInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpdateWithoutUsersInput = {
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prettyName?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProjectUpsertWithWhereUniqueWithoutUsersInput = {
  create: ProjectCreateWithoutUsersInput;
  update: ProjectUpdateWithoutUsersInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectWhereInput = {
  AND?: InputMaybe<Array<ProjectWhereInput>>;
  NOT?: InputMaybe<Array<ProjectWhereInput>>;
  OR?: InputMaybe<Array<ProjectWhereInput>>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  prettyName?: InputMaybe<StringFilter>;
};

export type ProjectWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  aggregateProject: AggregateProject;
  aggregateUser: AggregateUser;
  clerkInvitations: Array<Invitation>;
  clerkUsers: Array<ClerkUserModel>;
  files: Array<FileData>;
  findFirstProject?: Maybe<Project>;
  findFirstUser?: Maybe<User>;
  groupByProject: Array<ProjectGroupBy>;
  groupByUser: Array<UserGroupBy>;
  me: User;
  project?: Maybe<Project>;
  projects: Array<Project>;
  user?: Maybe<User>;
  users: Array<User>;
};


export type QueryAggregateProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindFirstProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryFindFirstUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryGroupByProjectArgs = {
  by: Array<ProjectScalarFieldEnum>;
  having?: InputMaybe<ProjectScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryGroupByUserArgs = {
  by: Array<UserScalarFieldEnum>;
  having?: InputMaybe<UserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type QueryProjectsArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type RevokeInvitationData = {
  id: Scalars['String'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  projects: Array<Project>;
  updatedAt: Scalars['DateTime'];
};


export type UserProjectsArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};

export type UserCount = {
  __typename?: 'UserCount';
  projects: Scalars['Int'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isAdmin: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
};

export type UserCreateInput = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  projects?: InputMaybe<ProjectCreateNestedManyWithoutUsersInput>;
};

export type UserCreateManyInput = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
};

export type UserGroupBy = {
  __typename?: 'UserGroupBy';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
};

export type UserOrderByWithAggregationInput = {
  _count?: InputMaybe<UserCountOrderByAggregateInput>;
  _max?: InputMaybe<UserMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserMinOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
  projects?: InputMaybe<ProjectOrderByRelationAggregateInput>;
};

export enum UserScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsAdmin = 'isAdmin',
  UpdatedAt = 'updatedAt'
}

export type UserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isAdmin?: InputMaybe<BoolWithAggregatesFilter>;
};

export type UserUpdateInput = {
  isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  projects?: InputMaybe<ProjectUpdateManyWithoutUsersInput>;
};

export type UserUpdateManyMutationInput = {
  isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  id?: InputMaybe<StringFilter>;
  isAdmin?: InputMaybe<BoolFilter>;
  projects?: InputMaybe<ProjectListRelationFilter>;
};

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type VerificationModel = {
  __typename?: 'VerificationModel';
  attempts?: Maybe<Scalars['Float']>;
  expireAt?: Maybe<Scalars['Float']>;
  nonce?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  strategy: Scalars['String'];
};

export type Web3WalletModel = {
  __typename?: 'Web3WalletModel';
  id: Scalars['String'];
  verification?: Maybe<VerificationModel>;
  web3Wallet: Scalars['String'];
};

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, createdAt: any, updatedAt: any, isAdmin: boolean, projects: Array<{ __typename?: 'Project', id: string, prettyName: string, description?: string | null, createdAt: any, updatedAt: any }> }> };

export type UserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, createdAt: any, updatedAt: any, isAdmin: boolean, projects: Array<{ __typename?: 'Project', id: string, prettyName: string, description?: string | null, createdAt: any, updatedAt: any }> } | null };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: { __typename?: 'User', id: string } | null };

export type UserFieldsFragment = { __typename?: 'User', id: string, createdAt: any, updatedAt: any, isAdmin: boolean, projects: Array<{ __typename?: 'Project', id: string, prettyName: string, description?: string | null, createdAt: any, updatedAt: any }> };

export type ProjectQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProjectQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id: string, prettyName: string, description?: string | null, createdAt: any, updatedAt: any } | null };

export type CreateProjectMutationVariables = Exact<{
  id: Scalars['String'];
  prettyName: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
}>;


export type CreateProjectMutation = { __typename?: 'Mutation', createProject: { __typename?: 'Project', id: string, prettyName: string, description?: string | null, createdAt: any, updatedAt: any } };

export type ProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', id: string, prettyName: string, description?: string | null, createdAt: any, updatedAt: any }> };

export type DeleteProjectMutationVariables = Exact<{
  Id: Scalars['String'];
}>;


export type DeleteProjectMutation = { __typename?: 'Mutation', deleteProject?: { __typename?: 'Project', id: string } | null };

export type ProjectFieldsFragment = { __typename?: 'Project', id: string, prettyName: string, description?: string | null, createdAt: any, updatedAt: any };

export type ClerkInvitationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClerkInvitationsQuery = { __typename?: 'Query', clerkInvitations: Array<{ __typename?: 'Invitation', id: string, emailAddress: string, projects: Array<string>, createdAt: any, updatedAt: any }> };

export type ClerkCreateInvitationMutationVariables = Exact<{
  emailAddress: Scalars['String'];
  projects: Array<Scalars['String']> | Scalars['String'];
}>;


export type ClerkCreateInvitationMutation = { __typename?: 'Mutation', clerkCreateInvitation?: { __typename?: 'Invitation', id: string, emailAddress: string, projects: Array<string>, createdAt: any, updatedAt: any } | null };

export type RevokeClerkInvitationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RevokeClerkInvitationMutation = { __typename?: 'Mutation', clerkRevokeInvitation?: { __typename?: 'Invitation', id: string, emailAddress: string, projects: Array<string>, createdAt: any, updatedAt: any } | null };

export type ClerkInvitationFieldsFragment = { __typename?: 'Invitation', id: string, emailAddress: string, projects: Array<string>, createdAt: any, updatedAt: any };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, createdAt: any, updatedAt: any, isAdmin: boolean, projects: Array<{ __typename?: 'Project', id: string, prettyName: string, description?: string | null, createdAt: any, updatedAt: any }> } };

export type MeFieldsFragment = { __typename?: 'User', id: string, createdAt: any, updatedAt: any, isAdmin: boolean, projects: Array<{ __typename?: 'Project', id: string, prettyName: string, description?: string | null, createdAt: any, updatedAt: any }> };

export type FilesQueryVariables = Exact<{ [key: string]: never; }>;


export type FilesQuery = { __typename?: 'Query', files: Array<{ __typename?: 'FileData', Key: string, Project: string, Name: string, Description?: string | null, SizeBytes: number, Modified: any }> };

export type DeleteFileMutationVariables = Exact<{
  Key: Scalars['String'];
}>;


export type DeleteFileMutation = { __typename?: 'Mutation', deleteFile: boolean };

export type FileFieldsFragment = { __typename?: 'FileData', Key: string, Project: string, Name: string, Description?: string | null, SizeBytes: number, Modified: any };

export type ClerkUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ClerkUsersQuery = { __typename?: 'Query', clerkUsers: Array<{ __typename?: 'ClerkUserModel', id: string, profileImageUrl: string, twoFactorEnabled: boolean, updatedAt: number, createdAt: number, lastSignInAt?: number | null, firstName?: string | null, lastName?: string | null, primaryEmailAddressId?: string | null, primaryPhoneNumberId?: string | null, externalAccounts: Array<{ __typename?: 'ExternalAccountModel', id: string }>, emailAddresses: Array<{ __typename?: 'EmailAddressModel', id: string, emailAddress: string }>, phoneNumbers: Array<{ __typename?: 'PhoneNumberModel', id: string, phoneNumber: string, reservedForSecondFactor: boolean }> }> };

export type DeleteClerkUserMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type DeleteClerkUserMutation = { __typename?: 'Mutation', deleteClerkUser?: { __typename?: 'ClerkUserModel', id: string } | null };

export type ClerkUserFieldsFragment = { __typename?: 'ClerkUserModel', id: string, profileImageUrl: string, twoFactorEnabled: boolean, updatedAt: number, createdAt: number, lastSignInAt?: number | null, firstName?: string | null, lastName?: string | null, primaryEmailAddressId?: string | null, primaryPhoneNumberId?: string | null, externalAccounts: Array<{ __typename?: 'ExternalAccountModel', id: string }>, emailAddresses: Array<{ __typename?: 'EmailAddressModel', id: string, emailAddress: string }>, phoneNumbers: Array<{ __typename?: 'PhoneNumberModel', id: string, phoneNumber: string, reservedForSecondFactor: boolean }> };

export const ProjectFieldsFragmentDoc = gql`
    fragment ProjectFields on Project {
  id
  prettyName
  description
  createdAt
  updatedAt
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  createdAt
  updatedAt
  isAdmin
  projects {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;
export const ClerkInvitationFieldsFragmentDoc = gql`
    fragment ClerkInvitationFields on Invitation {
  id
  emailAddress
  projects
  createdAt
  updatedAt
}
    `;
export const MeFieldsFragmentDoc = gql`
    fragment MeFields on User {
  id
  createdAt
  updatedAt
  isAdmin
  projects {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;
export const FileFieldsFragmentDoc = gql`
    fragment FileFields on FileData {
  Key
  Project
  Name
  Description
  SizeBytes
  Modified
}
    `;
export const ClerkUserFieldsFragmentDoc = gql`
    fragment ClerkUserFields on ClerkUserModel {
  id
  profileImageUrl
  twoFactorEnabled
  updatedAt
  createdAt
  lastSignInAt
  firstName
  lastName
  externalAccounts {
    id
  }
  emailAddresses {
    id
    emailAddress
  }
  phoneNumbers {
    id
    phoneNumber
    reservedForSecondFactor
  }
  primaryEmailAddressId
  primaryPhoneNumberId
}
    `;
export const UsersDocument = gql`
    query Users {
  users {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserDocument = gql`
    query User($id: String!) {
  user(where: {id: $id}) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ProjectDocument = gql`
    query Project($id: String!) {
  project(where: {id: $id}) {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($id: String!, $prettyName: String!, $description: String) {
  createProject(
    data: {id: $id, prettyName: $prettyName, description: $description}
  ) {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      prettyName: // value for 'prettyName'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const ProjectsDocument = gql`
    query Projects {
  projects {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const DeleteProjectDocument = gql`
    mutation DeleteProject($Id: String!) {
  deleteProject(where: {id: $Id}) {
    id
  }
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const ClerkInvitationsDocument = gql`
    query ClerkInvitations {
  clerkInvitations {
    ...ClerkInvitationFields
  }
}
    ${ClerkInvitationFieldsFragmentDoc}`;

/**
 * __useClerkInvitationsQuery__
 *
 * To run a query within a React component, call `useClerkInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClerkInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClerkInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClerkInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<ClerkInvitationsQuery, ClerkInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClerkInvitationsQuery, ClerkInvitationsQueryVariables>(ClerkInvitationsDocument, options);
      }
export function useClerkInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClerkInvitationsQuery, ClerkInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClerkInvitationsQuery, ClerkInvitationsQueryVariables>(ClerkInvitationsDocument, options);
        }
export type ClerkInvitationsQueryHookResult = ReturnType<typeof useClerkInvitationsQuery>;
export type ClerkInvitationsLazyQueryHookResult = ReturnType<typeof useClerkInvitationsLazyQuery>;
export type ClerkInvitationsQueryResult = Apollo.QueryResult<ClerkInvitationsQuery, ClerkInvitationsQueryVariables>;
export const ClerkCreateInvitationDocument = gql`
    mutation ClerkCreateInvitation($emailAddress: String!, $projects: [String!]!) {
  clerkCreateInvitation(data: {emailAddress: $emailAddress, projects: $projects}) {
    ...ClerkInvitationFields
  }
}
    ${ClerkInvitationFieldsFragmentDoc}`;
export type ClerkCreateInvitationMutationFn = Apollo.MutationFunction<ClerkCreateInvitationMutation, ClerkCreateInvitationMutationVariables>;

/**
 * __useClerkCreateInvitationMutation__
 *
 * To run a mutation, you first call `useClerkCreateInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClerkCreateInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clerkCreateInvitationMutation, { data, loading, error }] = useClerkCreateInvitationMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      projects: // value for 'projects'
 *   },
 * });
 */
export function useClerkCreateInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ClerkCreateInvitationMutation, ClerkCreateInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClerkCreateInvitationMutation, ClerkCreateInvitationMutationVariables>(ClerkCreateInvitationDocument, options);
      }
export type ClerkCreateInvitationMutationHookResult = ReturnType<typeof useClerkCreateInvitationMutation>;
export type ClerkCreateInvitationMutationResult = Apollo.MutationResult<ClerkCreateInvitationMutation>;
export type ClerkCreateInvitationMutationOptions = Apollo.BaseMutationOptions<ClerkCreateInvitationMutation, ClerkCreateInvitationMutationVariables>;
export const RevokeClerkInvitationDocument = gql`
    mutation RevokeClerkInvitation($id: String!) {
  clerkRevokeInvitation(data: {id: $id}) {
    ...ClerkInvitationFields
  }
}
    ${ClerkInvitationFieldsFragmentDoc}`;
export type RevokeClerkInvitationMutationFn = Apollo.MutationFunction<RevokeClerkInvitationMutation, RevokeClerkInvitationMutationVariables>;

/**
 * __useRevokeClerkInvitationMutation__
 *
 * To run a mutation, you first call `useRevokeClerkInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeClerkInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeClerkInvitationMutation, { data, loading, error }] = useRevokeClerkInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevokeClerkInvitationMutation(baseOptions?: Apollo.MutationHookOptions<RevokeClerkInvitationMutation, RevokeClerkInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeClerkInvitationMutation, RevokeClerkInvitationMutationVariables>(RevokeClerkInvitationDocument, options);
      }
export type RevokeClerkInvitationMutationHookResult = ReturnType<typeof useRevokeClerkInvitationMutation>;
export type RevokeClerkInvitationMutationResult = Apollo.MutationResult<RevokeClerkInvitationMutation>;
export type RevokeClerkInvitationMutationOptions = Apollo.BaseMutationOptions<RevokeClerkInvitationMutation, RevokeClerkInvitationMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...MeFields
  }
}
    ${MeFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const FilesDocument = gql`
    query Files {
  files {
    ...FileFields
  }
}
    ${FileFieldsFragmentDoc}`;

/**
 * __useFilesQuery__
 *
 * To run a query within a React component, call `useFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFilesQuery(baseOptions?: Apollo.QueryHookOptions<FilesQuery, FilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
      }
export function useFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesQuery, FilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
        }
export type FilesQueryHookResult = ReturnType<typeof useFilesQuery>;
export type FilesLazyQueryHookResult = ReturnType<typeof useFilesLazyQuery>;
export type FilesQueryResult = Apollo.QueryResult<FilesQuery, FilesQueryVariables>;
export const DeleteFileDocument = gql`
    mutation DeleteFile($Key: String!) {
  deleteFile(data: {Key: $Key})
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      Key: // value for 'Key'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export const ClerkUsersDocument = gql`
    query ClerkUsers {
  clerkUsers {
    ...ClerkUserFields
  }
}
    ${ClerkUserFieldsFragmentDoc}`;

/**
 * __useClerkUsersQuery__
 *
 * To run a query within a React component, call `useClerkUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClerkUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClerkUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useClerkUsersQuery(baseOptions?: Apollo.QueryHookOptions<ClerkUsersQuery, ClerkUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClerkUsersQuery, ClerkUsersQueryVariables>(ClerkUsersDocument, options);
      }
export function useClerkUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClerkUsersQuery, ClerkUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClerkUsersQuery, ClerkUsersQueryVariables>(ClerkUsersDocument, options);
        }
export type ClerkUsersQueryHookResult = ReturnType<typeof useClerkUsersQuery>;
export type ClerkUsersLazyQueryHookResult = ReturnType<typeof useClerkUsersLazyQuery>;
export type ClerkUsersQueryResult = Apollo.QueryResult<ClerkUsersQuery, ClerkUsersQueryVariables>;
export const DeleteClerkUserDocument = gql`
    mutation DeleteClerkUser($userId: String!) {
  deleteClerkUser(data: {userId: $userId}) {
    id
  }
}
    `;
export type DeleteClerkUserMutationFn = Apollo.MutationFunction<DeleteClerkUserMutation, DeleteClerkUserMutationVariables>;

/**
 * __useDeleteClerkUserMutation__
 *
 * To run a mutation, you first call `useDeleteClerkUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClerkUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClerkUserMutation, { data, loading, error }] = useDeleteClerkUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteClerkUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClerkUserMutation, DeleteClerkUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClerkUserMutation, DeleteClerkUserMutationVariables>(DeleteClerkUserDocument, options);
      }
export type DeleteClerkUserMutationHookResult = ReturnType<typeof useDeleteClerkUserMutation>;
export type DeleteClerkUserMutationResult = Apollo.MutationResult<DeleteClerkUserMutation>;
export type DeleteClerkUserMutationOptions = Apollo.BaseMutationOptions<DeleteClerkUserMutation, DeleteClerkUserMutationVariables>;